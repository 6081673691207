@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*, .Kalend__main * {
  font-family: 'Open Sans', sans-serif;
}

input, .p-dropdown-label {
  font-family: 'Open Sans', sans-serif !important;
}

a, a:hover, a:visited {
  color: #d58320;
}

.transparent {
  background: transparent !important;
}

.p-menubar-start a img {
  margin-top: 5px !important;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #2196f3 !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  color: #d58320 !important;
  background-color: transparent !important;
  border-bottom: 3px solid #d58320 !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

.p-tabview .p-tabview-nav-btn {
  color: #d58320 !important;
}

table tr, table th, table td, .p-paginator {
  background: transparent !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #d58320 !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #d58320 !important;
}

.right a.w-100:hover .p-card {
  background: #fe9f29;
  box-shadow: none !important;
}

.LoginPage {
  background: #fff;
}

.DashboardPage .right {
  background: #d58320;
}

.ModuleDashboardPage .right {
  background: #d58320;
}

.text-primary, p-text-primary {
  color: #d58320;
}

.text-secondary, p-text-secondary, h4.p-text-secondary {
  color: #6c757d !important
}

/* layout mobile overrides */

.p-menubar .p-menubar-button {
  margin-top: -55px;
  float: right !important;
}
